<template>
  <div
      :class="[(cor == 1)? 'red-button':'defaul-button', (disable == true)? 'disable-button':'']"
  >
    <i
        v-if="setaEsquerda === true"
        class="las la-arrow-left la-2x icon-esquerda"
    ></i>
    {{ text }}
    <i
        v-if="seta === true"
        class="las la-arrow-right la-2x icon"
    ></i>
  </div>
</template>

<script>
export default {
  name: "DefaultButton",
  props: {
    disable: {
      default: false
    },
    cor: {
      default: 0
    },
    text: {},
    transparent: {},
    full: {},
    half: {},
    small: {},
    seta: {
      default: false,
    },
    setaEsquerda: {
      default: false,
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.defaul-button {
  width: 100%;
  cursor: pointer;
  height: 48px;
  background: #528124;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 10px;
}

.red-button {
  width: 100%;
  cursor: pointer;
  height: 48px;
  background: rgba(192, 57, 43, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 10px;
}

.disable-button{
  width: 100%;
  cursor: pointer;
  height: 48px;
  background: rgba(82, 129, 36, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 10px;
}

.defaul-button:hover {
  background: rgba(82, 129, 36, 0.8);
  opacity: 0.8;
  border: 2px solid #528124 !important;
}

.red-button:hover {
  background: rgba(192, 57, 43, 0.8);
  opacity: 0.8;
  border: 2px solid #c0392b !important;
}

.defaul-button.transparent {
  background: transparent;
  border: 2px solid #3c2e88 !important;
  color: #528124;
}

.defaul-button.small {
  width: 25% !important;
}

.defaul-button.half {
  width: 50% !important;
}

.defaul-button.full {
  width: 100% !important;
}

.icon {
  padding-left: 10px;
}


.icon-esquerda {
  padding-right: 10px;
}

@media only screen and (max-width: 600px) {
  .defaul-button {
    width: 100% !important;
  }
}
</style>
